<template>
    <label v-bind="$attrs"
        ><strong>{{ label }}<sup v-if="required">*</sup></strong></label
    >
</template>

<script>
export default {
    name: "customLabel",
    props: {
        label: { default: "CustomLabel", type: String },
        required: { default: false, type: Boolean },
    },
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

